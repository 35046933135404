import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AuthContext } from '../../contexts/AuthContext';
import { StyledButton } from './styles';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://ride.com/">
                Ride
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function SignUpDriver() {
    const context = React.useContext(AuthContext)
    const [term_status, setTermStatus] = React.useState(false)
    const navigate = useNavigate()
    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log("EVENTED")
        const data = new FormData(event.currentTarget);

        if (data.get('email') == null) {
            return toast.error('Email is required')
        }
        if (data.get('password') == null) {
            return toast.error('Password is required')
        }
        if (data.get('firstName') == null) {
            return toast.error('First name is required')
        }
        if (data.get('lastName') == null) {
            return toast.error('Last name is required')
        }
        if (data.get('model') == null) {
            return toast.error('Model is required')
        }
        if (data.get('brand') == null) {
            return toast.error('Brand is required')
        }
        if (!term_status) {
            return toast.error('You must agree with terms of use')
        }

        const { error } = await context.register({
            email: data.get('email'),
            telephone: data.get('telephone'),
            password: data.get('password'),
            name: data.get('firstName') + ' ' + data.get('lastName'),
            terms_status: term_status,
            model: data.get('model'),
            brand: data.get('brand')
        })

        // if error

        if (Array.isArray(error)) {
            toast.error(error[0])
        }

        if (error) {
            return toast.error(error)
        }

        toast.success('Account created successfully')
        navigate('/login')
    };

    return (
        <>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Sign up
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="given-name"
                                    name="firstName"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="First Name"
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Last Name"
                                    name="lastName"
                                    autoComplete="family-name"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="model"
                                    label="Car Model"
                                    type="text"
                                    id="model"
                                    autoComplete="car-model"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="brand"
                                    label="Car Brand"
                                    type="text"
                                    id="brand"
                                    autoComplete="car-brand"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox onChange={() => setTermStatus(!term_status)} value="allowExtraEmails" color="primary" />}
                                    label="I agree with terms of use."
                                />
                            </Grid>
                        </Grid>
                        <StyledButton
                            type="submit"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign Up
                        </StyledButton>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link href="/login" variant="body2">
                                    Already have an account? Sign in
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 5 }} />
            </Container>
        </>
    );
}