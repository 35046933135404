import { destroyCookie, parseCookies, setCookie } from "nookies";
import React, { createContext, useEffect, useState } from "react";

import { api } from "../services/api";
import {
  SignOut,
  recoverUserInformation,
  registerRequest,
  signInRequest,
} from "../services/auth";

const AuthContext = createContext({});

function AuthProvider({ children }) {
  const inStorage = localStorage.getItem("user-ride");
  const [user, setUser] = useState(inStorage ? JSON.parse(inStorage) : null);
  const lastRecover = React.useRef(Date.now());
  let isAuthenticated = !!user;
  const recoverUserData = React.useCallback(async () => {
    const inStorage = localStorage.getItem("user-ride");
    const lastRecovered = localStorage.getItem("user-ride-recovered");
    if (inStorage && lastRecovered && Date.now() < lastRecovered + 60 * 60) {
      setUser(JSON.parse(inStorage));
    }

    if (token) {
      const response = await recoverUserInformation();
      if (!response) {
        return;
      }
      localStorage.setItem("user-ride-recovered", Date.now());
      localStorage.setItem("user-ride", JSON.stringify(response));
    }
  }, []);
  const { "ride.token": token } = parseCookies();
  useEffect(() => {
    if (
      token &&
      !user &&
      Date.now() > lastRecover.current + 10 &&
      !isAuthenticated
    ) {
      recoverUserData();
    }
  }, [token]);

  const signOut = async () => {
    localStorage.removeItem("user-ride");
    localStorage.removeItem("ride-token-storaged");
    setUser(null);
    const { error } = await SignOut();

    if (error) {
      return;
    }
    setUser(null);
    destroyCookie(null, "ride.token");
  };

  const updateProfile = async () => {
    localStorage.removeItem("user-ride");

    try {
      const res = await api.get("auth/me");

      localStorage.setItem("user-ride", JSON.stringify(res.data));
      setUser(res.data);
    } catch (e) {
      setUser(null);
    }
  };

  const signIn = async ({ email, password, remember_me }) => {
    const { token, user, error } = await signInRequest({
      email,
      password,
    });

    if (error) {
      return {
        error,
      };
    }

    if (remember_me) {
      setCookie(undefined, "ride.token", token, {
        maxAge: 60 * 60 * 72, // 3 days
      });
    } else {
      setCookie(undefined, "ride.token", token, {
        maxAge: 60 * 60 * 3, // 3 hours
      });
    }
    api.defaults.headers["Authorization"] = `Bearer ${token}`;
    localStorage.setItem("user-ride", JSON.stringify(user));
    localStorage.setItem("ride-token-storaged", token);
    setUser(user);
    return { token };
  };

  const register = async ({
    email,
    password,
    telephone,
    terms_status,
    name,
    model,
    brand,
  }) => {
    const { error, data } = await registerRequest({
      email,
      password,
      telephone,
      terms_status,
      name,
      model,
      brand,
    });

    if (error && !data) {
      return {
        error,
      };
    }

    setUser(data);

    return {
      error: null,
    };
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        signIn,
        updateProfile,
        signOut,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
