import React, { useEffect, useState } from "react";
import { GoogleMap, useJsApiLoader, OverlayView } from '@react-google-maps/api';
import SearchIcon from '@mui/icons-material/Search';
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import { getSuggestions } from "./create_ride/create_ride";
import useUserisDriver from "../hooks/useUserisDriver";
import NavMenu from "../components/navmenu/navmenu";
import AcceptModal from "../components/modal/TransictionModal";

const API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
export function getSuggestionsAndReturnIp(searchTerm) {
  const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(searchTerm)}&key=${API_KEY}&region=us`;
  return new Promise((resolve, reject) => {
    fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        if (data.status === 'OK') {
          if (Array.isArray(data.results) && data.results.length > 0) {
            const formattedResults = data.results.map(result => {
              const formattedAddress = result.formatted_address.replace(/,\s*Brazil$/i, ''); // Remove "Brazil" from the end of the address
              return { ...result, formatted_address: formattedAddress };
            });
            resolve(formattedResults);
          }
        } else {
          reject(false)
        }
      })
      .catch(error => {
        console.error('Error:', error);
        reject(false)
      });

  })
}

const Home = () => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: API_KEY,
    libraries: [
      'places'
    ],


  });
  const [coords, setCoords] = useState({ latitude: 51.505, longitude: -0.09, default: true });
  const [map, setMap] = useState(null);
  const isRider = useUserisDriver();

  const [searchItens, setSearchItens] = useState([]);
  const [navbarInUse, setNavbarInUse] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [searchLocation, setSearchLocation] = useState("");
  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds({
      lat: coords.latitude,
      lng: coords.longitude - 0.00001,
    });
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const handleSearchLocation = (event) => {
    setSearchLocation(event.target.value);
  }

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    // Get the user's location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCoords({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            default: false
          });
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }, []);


  useEffect(() => {
    if (searchLocation) {
      getSuggestions(searchLocation, setSearchItens)
    }
  }, [
    searchLocation
  ])

  const navigate = useNavigate();

  const navigateToProfile = () => {
    setModalOpen(false);
    navigate('/profile');
  }


  const RideOptions = () => {
    return (
      <div className="ride-options">
        <div className="effect"></div>
        <div className="options">
          <div
            className="option"
            style={{
              marginBottom: '1rem'
            }}
            onClick={() => (window.location.href = '/maps/' + coords.latitude + '/' + coords.longitude)}
          >
            <div>
              <h2>Ask for a Ride</h2>
              <p>Travel Comfortably and Pay cheap.</p>
            </div>
            <img width={'42px'} src="/question.svg" alt="Question" />
          </div>
          <div className="option" style={{ marginBottom: '5rem' }} onClick={() => {
            if (!isRider) {
              setModalOpen(true);
            } else {
              navigate('/rider');
            }
          }}>
            <div>
              <h2>give a ride</h2>
              <p>Use your car and share</p>
            </div>
            <img src="/sport-car.png" style={{ width: '4.4rem' }} alt="Car" />
          </div>
        </div>
        <NavMenu />
      </div>
    );
  };

  const renderBlueBall = (overlayView) => {
    return (
      <>
        <div id="pin-user"></div>
      </>
    );
  };

  return isLoaded ? (
    <>
      <div
        id="tag-super"
      >
        Driver
      </div>
      <div className="search-bar-map"
        style={{ position: 'fixed', zIndex: 10, top: isRider ? '2rem' : '0' }}
      >
        <div className="first-box">
          <input id="input-ride-search" className="searchbox"
            onFocus={() => {
              setNavbarInUse(true);
            }}
            type="text" placeholder="Search location" value={searchLocation} onChange={handleSearchLocation}
          />
          <CloseIcon className="closebox"
            style={{
              cursor: 'pointer'
            }}
            onClick={() => {
              setSearchLocation("")
            }}
          />
          <button className="icon-search" onClick={async () => {
            try {
              const data = await getSuggestionsAndReturnIp(searchLocation)

              setCoords({
                latitude: data[0].geometry.location.lat,
                longitude: data[0].geometry.location.lng,
              })
            } catch (error) {
              console.log('error while fetching address for lat,lng', error)
            }
          }}>
            <SearchIcon />
          </button>
        </div>
      </div>
      <div id="options">
        {
          navbarInUse && searchItens.length > 0 && searchItens.map((item, index) => {
            return (
              <div className="option" key={index} onClick={() => {
                setNavbarInUse(false);
                setSearchLocation(item.formatted_address);
              }}
                style={{
                  width: '100%'
                }}
              >
                <p>
                  {item.formatted_address}
                </p>
              </div>
            )
          })
        }

      </div>
      <GoogleMap
        mapContainerStyle={{
          height: "100vh",
          width: "100%",
          position: 'absolute',
          top: '0',
          left: '0',
        }}
        center={{
          lat: coords.latitude,
          lng: coords.longitude,
        }}
        clickableIcons={false}
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {!coords.default && (
          <OverlayView
            position={{
              lat: coords.latitude,
              lng: coords.longitude,
            }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={(width, height) => ({
              x: -width / 2,
              y: -height / 2,
            })}
          >
            {renderBlueBall()}
          </OverlayView>
        )}
        <RideOptions />
        <Navbar />
      </GoogleMap>
      <AcceptModal
        open={isModalOpen}
        setOpen={setModalOpen}
        acceptFunction={navigateToProfile}
        text="You need to complete your driver information first!"
        accept_text="Go to Profile"
        cancel_text="Cancel"
      />
    </>
  ) : (
    <></>
  );
};

export default Home;
