import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Navbar from '../components/Navbar';
import { AuthContext } from '../contexts/AuthContext';
import { StyledButton } from './login/styles';


export default function SignIn() {
  const context = React.useContext(AuthContext)
  const [remember_me, setRememberMe] = React.useState(false)
  const navigate = useNavigate()
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (data.get('email') == null) {
      return toast.error('Email is required')
    } else if (data.get('password') == null) {
      return toast.error('Password is required')
    }

    const { error } = await context.signIn({
      email: data.get('email'),
      password: data.get('password'),
      remember_me
    })

    console.log(error)
    if (Array.isArray(error)) {
      toast.error(error[0])
      return
    }

    console.log("Comming here")
    if (error) {
      console.log(error)
      toast.error(error)
      return;
    }

    return navigate('/home')
  };


  return (
    <>
      <Navbar
      />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h3" alignSelf='flex-start' color={'gray'} fontWeight={'light'}>
          Ride to You Terms of Service
          </Typography>
          <Typography variant="subtitle1" alignSelf='flex-start' color={'gray'} fontWeight={'light'}>
          Effective Date: December 13, 2024
          </Typography>
          <Typography variant="body1" alignSelf='flex-start' color={'gray'} fontWeight={'light'}>
          Welcome to Ride to You! These Terms of Use ("Terms") govern your access to and use of the Ride to You mobile application ("App") and related services (collectively, "Services"). By accessing or using the Services, you agree to these Terms. If you do not agree, you may not use the Services.
          </Typography>
          <Typography component="h4" variant="h3" alignSelf='flex-start' color={'gray'} fontWeight={'light'}>
          1. Acceptance of Terms
          </Typography>
          <Typography variant="body1" alignSelf='flex-start' color={'gray'} fontWeight={'light'}>
          By creating an account, accessing, or using our Services, you confirm that you are at least 18 years old and legally capable of entering into this agreement. If you are using the Services on behalf of a company, organization, or other entity, you represent that you have the authority to bind that entity to these Terms.
          </Typography>
          <Typography component="h4" variant="h3" alignSelf='flex-start' color={'gray'} fontWeight={'light'}>
          2. Modifications to Terms
          </Typography>
          <Typography variant="body1" alignSelf='flex-start' color={'gray'} fontWeight={'light'}>
          We may revise these Terms at any time. Changes will be effective when posted to the App. Your continued use of the Services after any updates constitutes acceptance of the revised Terms.
          </Typography>
          <Typography component="h4" variant="h3" alignSelf='flex-start' color={'gray'} fontWeight={'light'}>
          3. Account Creation and Responsibilities
          </Typography>
          <Typography variant="body1" alignSelf='flex-start' color={'gray'} fontWeight={'light'}>
          - You must create an account to use our Services.<br/>
          - You are responsible for maintaining the confidentiality of your account credentials and for all activities under your account.<br/>
          - You agree to provide accurate and up-to-date information during registration and to update it as necessary.
          </Typography><Typography component="h4" variant="h3" alignSelf='flex-start' color={'gray'} fontWeight={'light'}>
          4. Use of Services
          </Typography>
          <Typography variant="body1" alignSelf='flex-start' color={'gray'} fontWeight={'light'}>
          - The Services provide a platform to connect riders and drivers. Ride to You does not provide transportation services directly and is not a transportation carrier. <br/>
          - Drivers are independent contractors, not employees of Ride to You.<br/>
          - You agree to use the Services for lawful purposes and in compliance with all applicable laws and regulations.
          </Typography>
          <Typography component="h4" variant="h3" alignSelf='flex-start' color={'gray'} fontWeight={'light'}>
          5. Payments
          </Typography>
          <Typography variant="body1" alignSelf='flex-start' color={'gray'} fontWeight={'light'}>
          - Riders are responsible for paying the fare and any applicable fees for each ride.<br/>
          - Payment methods must be added to your account, and charges will be processed automatically after each ride.<br/>
          - Ride to You may apply dynamic pricing based on demand and other factors.
          </Typography>
          <Typography component="h4" variant="h3" alignSelf='flex-start' color={'gray'} fontWeight={'light'}>
          6. Cancellation Policy
          </Typography>
          <Typography variant="body1" alignSelf='flex-start' color={'gray'} fontWeight={'light'}>
          - Riders may cancel a ride within a specified timeframe. Fees may apply for cancellations made outside of this timeframe.<br/>
          - Drivers may cancel a ride for legitimate reasons. Excessive cancellations by either party may result in account suspension.
          </Typography>
          <Typography component="h4" variant="h3" alignSelf='flex-start' color={'gray'} fontWeight={'light'}>
          7. Disputes
          </Typography>
          <Typography variant="body1" alignSelf='flex-start' color={'gray'} fontWeight={'light'}>
          - Any disputes regarding fares, cancellations, or other issues should be reported through the App within 48 hours of the incident.<br/>
          - Ride to You reserves the right to make final determinations on disputes at its sole discretion.
          </Typography>
          <Typography component="h4" variant="h3" alignSelf='flex-start' color={'gray'} fontWeight={'light'}>
          8. Limitation of Liability
          </Typography>
          <Typography variant="body1" alignSelf='flex-start' color={'gray'} fontWeight={'light'}>
          - Ride to You is not liable for the actions, omissions, or conduct of drivers or riders.<br/>
          - We are not responsible for delays, cancellations, or other issues arising from circumstances beyond our control.<br/>
          - Our liability is limited to the maximum extent permitted by law.
          </Typography>
          <Typography component="h4" variant="h3" alignSelf='flex-start' color={'gray'} fontWeight={'light'}>
          9. Termination
          </Typography>
          <Typography variant="body1" alignSelf='flex-start' color={'gray'} fontWeight={'light'}>
          - We may terminate or suspend your account at any time for violating these Terms or for any other reason at our discretion.<br/>
          - You may terminate your account by contacting customer support.
          </Typography>
          <Typography component="h4" variant="h3" alignSelf='flex-start' color={'gray'} fontWeight={'light'}>
          10. Privacy Policy
          </Typography>
          <Typography variant="body1" alignSelf='flex-start' color={'gray'} fontWeight={'light'}>
          Your use of the Services is also governed by our Privacy Policy, which explains how we collect, use, and protect your information.
          </Typography>
          <Typography component="h4" variant="h3" alignSelf='flex-start' color={'gray'} fontWeight={'light'}>
          11. Governing Law
          </Typography>
          <Typography variant="body1" alignSelf='flex-start' color={'gray'} fontWeight={'light'}>
          These Terms are governed by the laws of the United States and the state in which the Services are primarily used, without regard to conflict of laws principles.
          </Typography>
          <Typography component="h4" variant="h3" alignSelf='flex-start' color={'gray'} fontWeight={'light'}>
          12. Contact Information
          </Typography>
          <Typography variant="body1" alignSelf='flex-start' color={'gray'} fontWeight={'light'}>
          For questions or concerns about these Terms, contact us at support@ridetoyou.com.
          By using Ride to You, you acknowledge that you have read, understood, and agreed to these Terms of Use.
          </Typography>
        </Box>
      </Container>
    </>
  );
}